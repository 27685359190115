import styled from 'styled-components';

export const Container = styled.div``;

export const ContentPublications = styled.div`
flex-direction: row;
width: 100%;
padding-top: 3rem;

p {
  font-weight: bold;
  color: #00204B;
  font-size: 18px;
  margin-bottom: 15px;
}
`;

export const Content = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;

export const ContentPublicationsCards = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media(max-width: 770px){
      padding: 0;
    }
`;

export const ContainerHeaderDashboard = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media(max-width: 1100px){
    width: 100%;
    padding-left: 0;
  }
`;

export const ContainerHeaderDashboardCards = styled.div`
 width: 100%;
 display: flex;
 justify-content: space-between;
 `;

 export const ContainerLerTudo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
 `;

export const ContainerHeaderDashboardButtons = styled.div`
  min-width: 30%;
  padding-left: 5%;
  align-self: center;

`;

export const ContentTitlePublications = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #00204B;
  margin-bottom: 0.5rem;

  h3 {
    margin-left: 1rem;
  }

  @media(max-width: 600px){


    h3 {
      font-size: 16px;
    }
  }
`;


export const CardDatesContainer = styled.div`
min-width: 30%;
margin-left: 5%;
align-self: center;
border: 1px solid gray;
border-radius: 20px;
height: 80%;
justify-content: space-around;
align-items: center;
display: flex;

.cardcontent {
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

label {
  font-weight: bold;
}

p {
  font-weight: bold;
  margin-left: 10px;
}

img {
  width: 24px;
  height: 24px;
}

@media(max-width: 1100px){
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  .cardcontent {
    margin-top: 5px;
  }
}

`;
