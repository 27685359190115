import styled from 'styled-components';

export const Container = styled.input`
    width: 100%;

    margin: 7px 0;
    padding: 20px;

    background-color: #f8f8f8;
    border-radius: 5px;
`;

export const Label  = styled.label``;
