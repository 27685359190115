import React, { useEffect, useState } from 'react';

import { CardDatesContainer, Container, ContainerContent }  from './styles';

import listImg from '../../assets/list.svg';
import tribunalImg from '../../assets/tribunal.svg';
import marteloImg from '../../assets/martelo.svg';
import shareImg from '../../assets/share.svg';
import clockImg from '../../assets/clock.svg';
import closeImg from '../../assets/close.svg';
import check from '../../assets/verifica.png';
import printer from '../../assets/printer.svg';
import calendarImg from '../../assets/calendar.svg';
import api from '../../services/api';
import { IPublicationDetail } from '../../models/Publication';
import formatDate from '../../utils/formatDate';
import {isMobile} from 'react-device-detect';
import {useAuth} from '../../contexts/auth';
import { saveAs } from 'file-saver';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { AddToCalendarButton } from 'add-to-calendar-button-react';


export interface PublicationBox extends IPublicationDetail {
  lerTodas?: boolean
  deletedIcon?: boolean
  onPrint?: (publicacaoList: any, date: string) => any
}

const PublicationBox: React.FC<PublicationBox> = ({ data, lerTodas, onPrint, deletedIcon }) => {

  const apagarPublicacao = async (id: number) => {
    await api.post('/publicacao/apagar', {publicacaoList: [id]})
      .then((res: any) => {
        document.location.reload()
      })
  }

  const restaurarPublicacao = async (id: number) => {
    await api.post('/publicacao/restaurar', {publicacao_id: id})
      .then((res: any) => {
        console.log("res", res)
        document.location.reload()
      })
  }

  const {user} = useAuth()
  const [ShowModal, setShowModal] = useState(false);
  const [dataInicial, setDataInicial] = useState(new Date());

  function normalizeCalendar(text: string) {
    let str = text;
    str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    return str
  }

  function createICSFile(publicationAgenda: IPublicationDetail) {

    if (dataInicial) {
      var dateFormatted = new Date(dataInicial.getTime() - (3 * 60 * 60 * 1000));
      console.log('dateFormatted', dateFormatted)
      let data = `${dateFormatted.toISOString().replace(/-/g, "").replace(/:/g, "").replace(/Z/g, "")}`
      console.log(`data`, data)


      const eventDetails = {
        uid: publicationAgenda.data.id,
        timestamp: data.split('.')[0],
        startTime:data.split('.')[0],
        endTime: data.split('.')[0],
        summary: `Publicacao: ${publicationAgenda.data.numero_processo}`,
        location: `https://www.informadores.com.br/share/${publicationAgenda.data.id}`,
        description: normalizeCalendar(publicationAgenda.data.processo_publicacao.substr(0, 30)),
      };

      const icsContent = generateICSContent(eventDetails);
      window.open( "data:text/calendar;charset=utf8," + escape(icsContent), '_blank');
    }
  }

  function hideKeyboard() {
    //this set timeout needed for case when hideKeyborad
    //is called inside of 'onfocus' event handler
    setTimeout(function() {

      //creating temp field
      var field = document.createElement('input');
      field.setAttribute('type', 'text');
      //hiding temp field from peoples eyes
      //-webkit-user-modify is nessesary for Android 4.x
      field.setAttribute('style', 'position:absolute; top: 0px; opacity: 0; -webkit-user-modify: read-write-plaintext-only; left:0px;');
      document.body.appendChild(field);

      //adding onfocus event handler for out temp field
      field.onfocus = function(){
        //this timeout of 200ms is nessasary for Android 2.3.x
        setTimeout(function() {

          field.setAttribute('style', 'display:none;');
          setTimeout(function() {
            document.body.removeChild(field);
            document.body.focus();
          }, 14);

        }, 200);
      };
      //focusing it
      field.focus();

    }, 50);
  }

  function generateICSContent(event: any) {
    return `BEGIN:VCALENDAR\nCALSCALE:GREGORIAN\nMETHOD:PUBLISH\nPRODID:-//My Calendar App//EN\nVERSION:2.0\nBEGIN:VEVENT\nUID:${event.uid}\nDTSTAMP:${event.timestamp}\nDTSTART:${event.startTime}\nDTEND:${event.endTime}\nSUMMARY:${event.summary}\nLOCATION:${event.location}\nDESCRIPTION:${event.description}\nEND:VEVENT\nEND:VCALENDAR`;
  }

  return (
    <>
    {
      user &&
      <Container
        color={lerTodas ? '#075600' : data.color ? data.color: ''}
      >
        <ContainerContent
          to={{
            pathname: `/${user.path}/list`,
            state: { publication: data }
          }}
        >
          <div className="wrap">
            <h4 style={{color: '#08324C'}}>Processo:</h4><label>{data.numero_processo}</label>
          </div>

          <div className="wrap">
            <h4 style={{color: '#08324C'}}>Cliente:</h4><label>{data.nome_escritorio}</label>
          </div>

          <div className="wrap">
            <h4 style={{color: '#08324C'}}>Variação encontrada:</h4><label>{data.nome_vinculo}</label>
          </div>

          <div className="wrap">
            <h4 style={{color: '#08324C'}}>Data Disponibilização:</h4><label>{formatDate(data.data_divulgacao)}</label>
            <h4 style={{color: '#08324C'}}>Data Publicação:</h4><label>{formatDate(data.data_publicacao)}</label>
          </div>

          <div>
            <img style={{marginLeft: 0, marginRight: 8}} src={tribunalImg} alt="Tribunal" /><h4 style={{color: '#08324C'}}>Diário: </h4><label>{data.nome_diario}</label>
          </div>

          <div>
            <img  style={{marginLeft: 0, marginRight: 8}} src={tribunalImg} alt="Tribunal" /><h4 style={{color: '#08324C'}}>Tribunal: </h4><label>{data.orgao_descricao}</label>
          </div>

          <div>
          <img  style={{marginLeft: 0, marginRight: 8}} src={tribunalImg} alt="Tribunal" /><h4 style={{color: '#08324C'}}>UF:</h4><label>{data.descricao_uf}</label>
          </div>

          <div>
            <img style={{marginLeft: 0, marginRight: 8}} src={marteloImg} alt="Martelo" /><h4 style={{color: '#08324C'}}>Orgão Secretaria: </h4><label>{data.vara_descricao}</label>
          </div>
          <div>

          </div>

          <div>
            <img  style={{marginLeft: 0, marginRight: 8, alignSelf: 'flex-start'}} src={listImg} alt="Contract" />
            {
              lerTodas ?
              <label className="clampLabel">{data.processo_publicacao}</label>
              :
              <label className="clampLabel" style={{ WebkitLineClamp: 3 }}>{data.processo_publicacao}</label>
            }
          </div>
        </ContainerContent>
        <div style={{borderBottom: '1px solid #00204B20'}}></div>
        <footer>
          {
            deletedIcon &&
              <>
                <div
                  onClick={() => {
                    window.open(`/share/${data.id}`, '_blank')
                  }}
                  style={{cursor: "pointer", height: 50, width: 120, justifyContent: 'center'}}>
                  <img src={shareImg} />
                  <label style={{cursor: 'pointer'}}>Compartilhar</label>
                </div>

                <div
                  onClick={() => {
                    setShowModal(true)
                  }}
                  style={{cursor: "pointer", height: 50, width: 120, justifyContent: 'center'}}>
                  <img src={calendarImg} />
                  <label style={{cursor: 'pointer'}}>Agendar</label>
                </div>
              </>
          }

          {
            onPrint &&
              <div
                onClick={() => { onPrint([data], formatDate(data.data_publicacao))}}
                style={{cursor: "pointer", height: 50, width: 120, justifyContent: 'center'}}
              >
                <img src={printer} />
                <label style={{cursor: 'pointer'}}>Imprimir</label>
              </div>
          }

          {
            deletedIcon ?
              <div
                onClick={() => { apagarPublicacao(data.id)}}
                style={{cursor: "pointer", height: 50, width: 120, justifyContent: 'center'}}
              >
                <img src={closeImg} />
                <label style={{cursor: 'pointer'}}>Apagar</label>
              </div>
            :
              <div
                onClick={() => { restaurarPublicacao(data.id) }}
                style={{cursor: "pointer", height: 50, width: 120, justifyContent: 'center'}}
              >
                <img src={check} height={20} width={20}/>
                <label style={{cursor: 'pointer'}}>Restaurar Publicação</label>
              </div>
          }
        </footer>

        {
          ShowModal &&
          <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', position: 'absolute', top: 0, left:0, right: 0, bottom: 0, backgroundColor: '#00000020', zIndex: 999, color: 'black'}}>
            <div style={{height: 250, width: 550, backgroundColor: 'white', marginTop: 200, borderRadius: 20, padding: 20, marginLeft: 8, marginRight: 8}}>
              <h2 style={{textAlign: 'center', marginTop: 20}}>Qual data deseja agendar</h2>
                <CardDatesContainer>
                  <div className="cardcontent" style={{border: '1px solid black', borderRadius: 8, padding: 8}}>
                    <img src={calendarImg} alt="Calendar"  style={{marginRight: 8}}/>
                    <DatePicker id="txtAgendaDate"
                      onInputClick={() => {
                        let element = document.getElementById('txtAgendaDate')
                        if (element) {
                          element.blur();
                        }
                      }} showTimeSelect dateFormat="dd/MM/yyyy HH:mm" onChange={(date: any) => setDataInicial(date)} selected={dataInicial} locale={"pt-BR"} />
                  </div>
                </CardDatesContainer>

                <div onClick={hideKeyboard} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginBottom: 20, width: '100%', gap: 12}}>
                  {console.log(dataInicial.toISOString().split('T')[1])}
                  <AddToCalendarButton
                    name={`Publicacao: ${data.numero_processo}`}
                    description={normalizeCalendar(data.processo_publicacao)}
                    options={['Apple', 'Google', 'MicrosoftTeams', 'Outlook.com', 'iCal', 'Microsoft365']}
                    location={`https://www.informadores.com.br/share/${data.id}`}
                    startDate={dataInicial ? dataInicial.toISOString().split('T')[0] : ''}
                    endDate={dataInicial ? dataInicial.toISOString().split('T')[0] : ''}
                    startTime={dataInicial ? dataInicial.toISOString().split('T')[1].split('.')[0] : ''}
                    endTime={dataInicial ? dataInicial.toISOString().split('T')[1].split('.')[0] : ''}
                    timeZone="America/Sao_Paulo"
                  />
                  {/* <button style={{padding: '10px 20px 10px 20px', backgroundColor: "#00204B", color: '#fff', fontWeight: 'bold', borderRadius: 20, height: 40, width: 100 }}
                    onClick={() => createICSFile({data: data})}
                  >
                    Agendar
                  </button> */}
                  <button style={{padding: '10px 20px 10px 20px', backgroundColor: "#fff", color: '#00204B', fontWeight: 'bold', borderRadius: 20, height: 40, width: 100, border: '1px solid #00204B' }}
                    onClick={() => {setShowModal(false)}}
                  >Cancelar</button>
                </div>
            </div>
          </div>
        }
      </Container>
    }


  </>
  )}


export default PublicationBox;
