import React from 'react';

import { Container }  from './styles';

const Content: React.FC = ({ children }) => (
    <Container id="container">
        {children}
    </Container>
);

export default Content;
