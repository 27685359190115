import styled from 'styled-components';

export const Container = styled.button`
    margin: 7px 0;
    padding: 15px 60px;
    width: 100%;

    align-items: center;
    justify-content: center;
    display: flex;

    border-radius: 30px;

    font-weight: bold;
    color: #fff;
    background-color: #08324C;

    transition: opacity .3s;

    img {
      margin-right: 10px;
    }

    &:hover{
        opacity: .7;
    }
`;
