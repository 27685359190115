import React from 'react';

import BigBox from '../../components/WalletBox';

import {Container,
  Content, ContentPublications,
   CardDatesContainer, ContentPublicationsCards,
    ContainerHeaderDashboard, ContainerHeaderDashboardCards,
     ContentTitlePublications, ContainerLerTudo}  from './styles';
import Button from '../../components/Button';

import { ReactComponent as PublicationIcon } from '../../assets/publication.svg';
import calendarImg from '../../assets/calendar.svg';
import listImg from '../../assets/list.svg';
import { useState } from 'react';
import { useEffect } from 'react';
import PublicationBox from '../../components/PublicationBox';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { IPublication } from '../../models/Publication';
import ClipLoader from "react-spinners/ClipLoader";
import { useAuth } from '../../contexts/auth';
import formatDate from '../../utils/formatDate';

import { saveAs } from "file-saver";
import {
  AlignmentType,
  Document,
  HeadingLevel,
  Packer,
  Paragraph,
  TabStopPosition,
  TabStopType,
  TextRun
} from "docx";
import { Alignment } from 'docx';

interface LocationState {
  publication: IPublication,
  typeInicial? : string
}

interface IPublicationDetail {
  id: number;
  codigo_escritorio: number;
  nome_escritorio: string;
  oab_estado: string;
  oab_numero: string;
  ano_publicacao: number;
  cidade_publicacao: string;
  cod_integracao: number;
  cod_publicacao: number;
  cod_vinculo: number;
  data_cadastro: string;
  nome_diario: string;
  data_divulgacao: string;
  data_publicacao: string;
  descricao_diario: string;
  descricao_uf: string;
  despacho_publicacao: string;
  edicao_diario: number;
  nome_vinculo: string;
  numero_processo: string;
  orgao_descricao: string;
  pagina_final: number;
  pagina_inicial: number;
  processo_publicacao: string;
  publicacao_corrigida: number;
  publicacao_exportada: number;
  vara_descricao: string;
}

const PublicationsDeleted: React.FC = () => {
  const [filterDate, setFilterDate] = useState(true);

  let { type } = useParams<any>()

  const location = useLocation<LocationState>();
  const history = useHistory()
  const { user } = useAuth();
  const [TypeFilter, setTypeFilter] = useState(type);

  const [publications, setPublications] = useState<IPublicationDetail[]>([]);
  const [showAllPublicacao, setShowAllPublicacao] = useState<boolean>(false);

  const [Total, setTotal] = useState(0);
  const [Lidas, setLidas] = useState(0);
  const [NaoLidas, setNaoLidas] = useState(0);
  const [loading, setLoading] = useState(true);
  const [Refresh, setRefresh] = useState(0);

  const [ShowModal, setShowModal] = useState(false);

  const [LoadingLeitura, setLoadingLeitura] = useState(false);

  const getPublications = async () => {
    setLoading(true)
    let publicacao = await api.post('/publicacao/getPublicacaoApagadas')
    setPublications(publicacao.data.value);
    setLoading(false)
  }

  useEffect(() => {
    console.log('publications', publications, publications.length)
  }, [publications])

  useEffect(() => {
    getPublications()
  }, [])

  return (
    <>
      { user &&
        <Container>
        <Content>
          <ContainerHeaderDashboard></ContainerHeaderDashboard>

          <ContentPublications>

          <ContentTitlePublications>
            <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
              <img src={listImg} alt="Contract"/>
              <h3>Publicações Apagadas</h3>
            </div>
            </ContentTitlePublications>

            <ContentPublicationsCards>
              {
                loading ?
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginTop: 40, marginBottom: 20, width: '100%', gap: 12}}>
                    <div style={{color: 'black'}}>Carregando Dados</div><ClipLoader color="#08324C" size={20}/>
                  </div>
                :
                publications.length > 0 ?
                    publications.map(publication => (
                      <>
                        <PublicationBox key={publication.id} data={publication} lerTodas={showAllPublicacao} deletedIcon={false}/>
                      </>
                    ))
                  :
                <div style={{height: 200, color: 'black', marginTop: 100, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', textAlign: 'center', gap: 12}}><PublicationIcon/> <span style={{fontWeight: 'bold'}}>Sem publicações para exibir</span></div>
                }
            </ContentPublicationsCards>
          </ContentPublications>
        </Content>
      </Container>
    }
    </>

  );
};

export default PublicationsDeleted;
