import React, { Component } from 'react';
import { Container, Content, ContainerRow, ContentPublication, Footer } from './styles';

import tribunalImg from '../../assets/tribunal.svg';
import marteloImg from '../../assets/martelo.svg';
import listImg from '../../assets/list.svg';
import shareImg from '../../assets/share.svg';
import clockImg from '../../assets/clock.svg';
import closeImg from '../../assets/close.svg';

import formatDate from '../../utils/formatDate';
import { useLocation } from 'react-router-dom';

const Contato:React.FC<Component> = () => {

  return (
    <Container>

      <Content>
        <iframe src="https://prius.com.br/fale-conosco/" width="100%" height="1100"></iframe>
      </Content>
    </Container>
  );
};

export default Contato;
