import React, { createContext, useState, useEffect, useContext } from 'react';
import api from '../services/api';

export interface UserData {
  id: string;
  nome: string;
  email: string;
  avatar: string;
  codigo_escritorio: number;
  escritorio: string;
  escritorioLogo: string;
  escritorio_desc: string;
  breakline: boolean;
  rodape: string;
  path: string;
}

interface AuthContextData {
  signed: boolean;
  user: UserData | null;
  loading: boolean;
  signIn(email: string, password: string, path: string, codigoEscritorio: number): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<UserData | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadSoragedData() {
      const storagedUser = localStorage.getItem('@Prius:user');
      const storagedToken = localStorage.getItem('@Prius:token');

      if (storagedUser && storagedToken) {
        api.defaults.headers['Authorization'] = `Bearer ${JSON.parse(storagedToken)}`;
        setUser(JSON.parse(storagedUser));
        setLoading(false);
      } else {
        setLoading(false);
      }
    }

    loadSoragedData();
  }, []);

  async function setUserAndToken(user: UserData, token: string) {
    setUser(user);

    api.defaults.headers['Authorization'] = `Bearer ${token}`;

    localStorage.setItem('@Prius:user', JSON.stringify(user));
    localStorage.setItem('@Prius:token', JSON.stringify(token));

  }

  async function signIn(email: string, password: string, path: string, codigoEscritorio: number) {
    const response = await api.post('/login_escritorio', { email, password, path, codigoEscritorio});

    await setUserAndToken(response.data.value, response.data.value.token);
    window.location.href = `/${path}/`
  }

  async function signOut() {
    let path = user?.path
    localStorage.clear()
    setUser(null);
    window.location.href = `/${path}`
  }

  return (
    <AuthContext.Provider
      value={{ signed: !!user, user, loading, signIn, signOut }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}
