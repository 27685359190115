export default {
    title: 'dark',

    colors: {
        primary: '#00204B',
        secondary: '#252A48',
        tertiary: '#313862',

        white: '#FFF',
        black: '#000',
        gray: '#BFBFBF',

        success: '#4E41F0',
        info: '#F7931B',
        warning: '#E44C4E',
    },
};
