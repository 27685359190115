import React, {FormEvent, useState, useEffect} from 'react';

// import logoImg from '../../assets/logo_prius.png';
import exitImg from '../../assets/exit.svg';

import Input from '../../components/Input';
import Button from '../../components/Button';

import {Link, useParams} from 'react-router-dom';

import {useAuth} from '../../contexts/auth';
import api from '../../services/api';

import {Container,Background, Content, Logo, Form, LinkRegister} from './styles';
import { toast } from 'react-toastify';

const SignIn: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [codigoEscritorio, setCodigoEscritorio] = useState<number>(0);

  const [logo, setLogo] = useState<string>('');
  const [nome, setNome] = useState<string>('');
  const [titulo, setTitulo] = useState<string>('');
  const [descricao, setDescricao] = useState<string>('');
  const [cover, setCover] = useState<string>('');

  const {signIn, signed, user} = useAuth()
  let { path } = useParams<any>()

  useEffect(() => {
    console.log('path', path)
  }, [path])

  function handleLogin(e: FormEvent) {
    e.preventDefault();

    if (email.length < 6) {
      toast.error('Informe um email válido', {position: 'top-right'});
      return;
    }

    if (password.length < 3) {
      toast.error('Informe uma senha válida', {position: 'top-right'});
      return;
    }

    signIn(email, password, path, codigoEscritorio).catch((err) => {
      toast.error('Usuário ou senha inválidos', {position: 'top-right'});
    });

  }

  useEffect(() => {

    api.post('/informador/getByPath', { path: path }).then(res => {

      let data = res.data.value
      setLogo(data.logomarca)
      setNome(data.nome)
      setTitulo(data.titulo_login)
      setDescricao(data.descricao_login)
      setCodigoEscritorio(data.id)
      if ((data.cover) && (data.cover.length === 0)) {
        setCover('')
      }
      else {
        setCover(data.cover)
      }
    })
    .catch(res =>
        toast.error('Não é possível pegar dados do informante',
        {position: 'top-right'})
    )

  }, [])

  return (
    <Container>
    <Content>

    <Logo>
        { logo && <img src={logo} alt="Informador" /> }
        <h2>{nome}</h2>
    </Logo>

      <Form onSubmit={handleLogin}>

        <Input
          type="email"
          placeholder="Email"
          value={email}
          required
          onChange={e => setEmail(e.target.value)}
        />
        <Input
          type="password"
          placeholder="Senha"
          value={password}
          required
          onChange={e => setPassword(e.target.value)}
        />

        <Button style={{marginTop: 40}} type="submit"> <img src={exitImg} alt="Entrar" /> Acessar o Sistema</Button>

        <Link style={{textDecoration: 'none'}} to="/">
        </Link>

      </Form>
      </Content>
      <Background cover={cover}>
        { titulo && <h1>{titulo}</h1>}
        { descricao && <h3>{descricao}</h3>}
      </Background>
    </Container>
  );
};

export default SignIn;
