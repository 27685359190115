import styled, {keyframes} from 'styled-components';

interface IContainerProps {
    color: string;
    onClick: () => {}
}

const animate = keyframes`
    0%{
        transform: translateX(100px);
        opacity: 0;
    }
    50%{
        opacity: .3;
    }
    100%{
        transform: translateX(0px);
        opacity: 1;
    }
`;

export const Container = styled.div<IContainerProps>`
    width: 33%;
    height: 100%;
    cursor: ${props => props.onClick !== undefined ? 'pointer' : 'cursor'} ;

    margin: 0 5px;

    background-color: ${props => props.color};
    color: ${props => props.theme.colors.white};

    border-radius: 20px;
    padding: 10px 20px;

    position: relative;
    overflow: hidden;

    animation: ${animate} .5s;

    > img {
        height: 30px;
        width: 30px;
        opacity: 0.7;
    }

    > h1 {
      color: #fff;
      text-align: right;
      font-size: 2rem;
    }

    > p {
        font-size: 12px;
        font-weight: 500;
        color: #fff;
    }

    @media(max-width: 770px){
        > span {
            font-size: 14px;
        }

        > p {
          font-size: 0.5rem;
        }

        > h1 {
            word-wrap: break-word;
            font-size: 22px;

            strong {
                display: inline-block;
                width: 100%;
                font-size: 16px;
            }
        }
    }

    @media(max-width: 420px){
        width: 100%;

        > h1 {
            display: flex;

            strong {
                position: initial;
                width: auto;
                font-size: 22px;
            }

            strong:after {
                display: inline-block;
                content: '';
                width: 1px;
            }
        }
    }
`;
