import styled from 'styled-components';

export const Container = styled.div`
`;

export const ContentPublications = styled.div`
flex-direction: row;
width: 100%;
padding-top: 3rem;

p {
  font-weight: bold;
  color: #00204B;
  font-size: 18px;
  margin-bottom: 15px;
}
`;

export const Content = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;

export const ContentPublicationsCards = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 3rem;

    @media(max-width: 770px){
      padding: 0;
    }
`;

export const ContainerHeaderDashboard = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export const ContainerHeaderDashboardCards = styled.div`
 width: 100%;
 display: flex;
 justify-content: space-between;
 `;

export const ContainerHeaderDashboardButtons = styled.div`
min-width: 30%;
padding-left: 5%;
align-self: center;

`;


export const CardDatesContainer = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: row !important;
  border: 1px solid gray;
  border-radius: 20px;
  justify-content: space-around;
  align-items: center;
  color: #00204B;
  margin-top: 20px;

  .dateContentLabel {
    display: flex;
    flex-direction: row;
    width: 20%;
    float: left;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  .dateContent {
    display: flex;
    flex-direction: row;
    width: 40%;
    float: left;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  .buttonFiltrar {
    display: flex;
    flex-direction: row;
    width: 180px;
    margin-right: 16px;
    float: left;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  .cardcontent {
    flex-direction: row;
    display: inline-flex;
    align-items: center;
    margin-left: 8px;
    width: 150px;
    margin-right: 8px;
  }

  label {
    font-weight: bold;
    margin-top: 8px;
  }

  p {
    font-weight: bold;
    margin-left: 10px;
  }

  img {
    width: 24px;
    height: 24px;
  }

  .react-date-picker__wrapper {
    border-radius: 8px !important;
    padding: 8px;
  }

  .teste {
    width: 100px;
  }

  @media(max-width: 1100px){
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    height: 190px;
    padding-bottom: 8px;

    .cardcontent {
      margin-top: 5px;
    }

    .dateContentLabel {
      width: 100% !important;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
    }

    .dateContent {
      display: flex;
      flex-direction: row;
      width: 100%;
      float: left;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
    }
  }
`;
