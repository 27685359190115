import React from 'react';
import { Switch, Route } from 'react-router-dom';

import SignIn from '../pages/SignIn';
import SemInformate from '../pages/SemInformate';
import Share from '../pages/share';

const AuthRoutes: React.FC = () => (
    <Switch>
        <Route path="/:path" component={SignIn} />
        <Route path="/share/:publicacao_id" component={() => { return (<>teste</>)}} />
        <Route path="/" component={SemInformate} />
    </Switch>
);

export default AuthRoutes;
