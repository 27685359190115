import React from 'react';

import {
    Container,
    Profile,
    HeaderProfile,
    Welcome,
    UserName,
    Avatar,
    Logo,
}  from './styles';

import logoImg from '../../assets/logo_prius.png';
import avatarImg from '../../assets/avatar.png';
import { useAuth } from '../../contexts/auth';

const MainHeader: React.FC = () => {

  const { user } = useAuth();

    React.useEffect(() => {
      console.log(user)
    }, [])

    return (
        <Container>
            <Profile>
            <Logo src={user?.escritorioLogo} />
                <HeaderProfile>
                <Welcome>{user?.escritorio}</Welcome>
                </HeaderProfile>
            </Profile>

            <Profile>
                <div style={{textAlign: 'right', marginRight: '1rem'}}>
                  <Welcome>{user?.nome}</Welcome>
                  <UserName>{user?.escritorio_desc}</UserName>
                </div>

                <Avatar src={user?.avatar ? user.avatar : avatarImg} />
            </Profile>
        </Container>
    );
}

export default MainHeader;
