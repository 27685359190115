import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.main`

  div {
    display: flex;
    margin-bottom: 1rem;
  }

  h4 {
    color: #00204B;
    margin-right: 20px;
  }

  label {
    font-weight: normal;
  }
`;

export const ContainerRow = styled.div`

  img {
    margin-right: 0.5rem;
  }
`;

export const ContentPublication = styled.div`
  color: #00204B;
  text-align: justify;
  margin-bottom: 5%;
  line-height: 30px;
  align-items: flex-start;
  margin: 20px 0;

  img {
    padding-right: 0.5rem;
  }
`;

export const Footer = styled.div`
  padding-top: 2.5rem;
  background-color: #fff;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #00204B;
  font-weight: bold;

  div {
    align-items: center;
    padding: 0 2%;
  }

  div img {
    margin-right: 0.5rem;
  }

  @media(max-width: 600px){
      font-size: 14px;

      img {
        width: 20px;
        height: 20px;
      }
    }

`;
