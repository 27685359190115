import React from 'react';
import CountUp from 'react-countup';

import { ReactComponent as ListImg } from '../../assets/list.svg';


import { Container }  from './styles';

interface IWalletBoxProps {
    title: string;
    amount: number;
    footerlabel: string;
    icon: 'dolar' | 'arrowUp' | 'arrowDown';
    color: string;
    onClick?: any,
    style?: React.CSSProperties
}

const WalletBox: React.FC<IWalletBoxProps> = ({
    title,
    amount,
    footerlabel,
    icon,
    color,
    onClick,
    style
}) => {
    return (
        <Container color={color} onClick={onClick} style={style}>
            <ListImg fill="transparent" stroke="white" />

            <h1>
                <CountUp
                    end={amount}
                    separator="."
                    decimal=","
                    decimals={0}
                />
            </h1>
            <p>{footerlabel}</p>
        </Container>
    );
}

export default WalletBox;
