import styled from 'styled-components';

export const ModalTitle = styled.h4`
  color: #00204B;
`;

export const ModalContent = styled.div`
  display: flex;
  padding: 20px;
`;

export const CardDate = styled.div`
  padding: 0px 30px;

  label {
    font-weight: bold;
    color: #00204B;
  }

  div {
    flex-direction: row;
    align-items: center;
    display: flex;
    padding: 10px 0;
  }

  img {
    margin-right: 0.5rem;
  }
`;
