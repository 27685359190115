import React from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from './styles/GlobalStyles';

import { useTheme } from './hooks/theme';

import Routes from './routes';

import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const App: React.FC = () => {
    const {theme} = useTheme();

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <Routes/>
            <ToastContainer />
        </ThemeProvider>
    );
}

export default App;
