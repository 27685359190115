import React from 'react';
import {Container} from './styles';

const SemInformante: React.FC = () => {

  return (
    <Container>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
        <h3>404 - Informante não localizado</h3>
      </div>
    </Container>
  );
};

export default SemInformante;
