import React from 'react';
import Modal from 'react-modal';
import Button from '../Button';

import { ModalTitle, ModalContent, CardDate } from './styles';

import filterImg from '../../assets/filter.svg';
import calendarImg from '../../assets/calendar.svg';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 20,
  },
  overlay: {
    background: 'rgba(196,196,196,0.5)',
  }
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');
interface StyleProp {
  showModal: boolean;
  setShowModal: any;
}

export default function ModalFilter({showModal, setShowModal}: StyleProp) {

  return (
    <div>
      <Modal
        isOpen={showModal}
        onRequestClose={setShowModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <ModalTitle>Filtrar</ModalTitle>
          <ModalContent>
          <CardDate>
            <label>Data Inicial</label>
            <div>
            <img src={calendarImg} alt="Calendar" />
            <label>20/05/2021</label>
            </div>
          </CardDate>

          <CardDate>
            <label>Data Final</label>
            <div>
            <img src={calendarImg} alt="Calendar" />
            <label>20/05/2021</label>
            </div>
          </CardDate>

        </ModalContent>

        <Button onClick={() => setShowModal(false)} type="button"><img src={filterImg} style={{width: 15}} />Filtrar</Button>
      </Modal>
    </div>
  );
}
