import React from 'react';

import BigBox from '../../components/WalletBox';
import MessageBox from '../../components/MessageBox';

import {Container, Content, ContentPublications, CardDatesContainer, ContentPublicationsCards, ContainerHeaderDashboard, ContainerHeaderDashboardCards, ContainerHeaderDashboardButtons} from './styles';
import Button from '../../components/Button';

import filterImg from '../../assets/filter.svg';
import calendarImg from '../../assets/calendar.svg';
import { useState } from 'react';
import { useEffect } from 'react';
import ModalFilter from '../../components/Modal';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { IPublication } from '../../models/Publication';
// import DatePicker from 'react-date-picker';
import { useHistory } from "react-router-dom";
import { useAuth } from '../../contexts/auth';
import ClipLoader from "react-spinners/ClipLoader";
import { ReactComponent as PublicationIcon } from '../../assets/publication.svg';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { execPath } from 'process';

interface ICounters {
  totalPublicacoes: number;
  totalPublicacoesLidas: number;
  totalPublicacoesNaoLidas: number;
}

const Dashboard: React.FC = () => {
  const [filterDate, setFilterDate] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const [dataInicial, setDataInicial] = useState<any>();
  const [dataFinal, setDataFinal] = useState<any>();

  const [Loading, setLoading] = useState(false);

  const history = useHistory()

  const { user, signOut } = useAuth();

  const [counters, setCounters] = useState<ICounters>({
    totalPublicacoes: 0,
    totalPublicacoesLidas: 0,
    totalPublicacoesNaoLidas: 0,
  } as ICounters);
  const [publications, setPublications] = useState<IPublication[]>([]);

  const getPublications = async () => {
    setLoading(true)

    if (dataInicial && dataFinal) {
      const dateFormatted = dataInicial.toLocaleDateString("pt-BR").split('/').reverse().join('-');
      const dateFormattedFinal = dataFinal.toLocaleDateString("pt-BR").split('/').reverse().join('-');
      try {
        let res = await api.post('/acesso/dash', {dataInicial: dateFormatted, dataFinal: dateFormattedFinal, all: true})
        console.log('res', res)
        setCounters(res.data.counters);
        setPublications(res.data.publicacoes);
      }
      catch(err) {
        console.log(err)
        if (err.response.status === 401) {
          signOut()
        }
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    if ((dataFinal) && (dataInicial) && (publications.length === 0)) {

      getPublications();
    }
  }, [dataInicial, dataFinal]);

  const openModal = () => {
    setShowModal(prev => !prev);
  }

  function handleSetFilterDate() {
    setFilterDate(prev => !prev);
  }

  useEffect(() => {

    let data = new Date()
    let dataFinalTemp = new Date()
    dataFinalTemp.setDate(data.getDate() + 30);
    let dataInicialTemp = new Date()
    dataInicialTemp.setDate(data.getDate() - 60);
    setDataInicial(dataInicialTemp)
    setDataFinal(dataFinalTemp)

  if(showModal)
    handleSetFilterDate();
  }, [showModal]);

  const CardDatesComponent = () => {
    return (
      <>
      {
        dataInicial && dataFinal &&
          <CardDatesContainer>
            <div className="dateContentLabel">
              <p style={{textAlign: 'left', marginTop: 16}}>Publicações</p>
            </div>
            <div className="dateContent">
              <label style={{width: 100, textAlign: 'right'}}>Data Inicial</label>

              <div className="cardcontent">
                <img src={calendarImg} alt="Calendar"  style={{marginRight: 8}}/>
                {/* <p>{dataInicial}</p> */}
                <div style={{width: 100}}>
                  <DatePicker
                    id="txtDataInicial"
                    onInputClick={() => {
                      let element = document.getElementById('txtDataInicial')
                      if (element) {
                        element.blur();
                      }
                    }}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date: any) => setDataInicial(date)}
                    selected={dataInicial}
                    locale={"pt-BR"}
                  />
                </div>
              </div>
            </div>

            <div className="dateContent" >
              <label  style={{width: 100, textAlign: 'right'}}>Data Final</label>
              <div className="cardcontent">
                <img src={calendarImg} alt="Calendar"  style={{marginRight: 8}} />
                <div style={{width: 100}}>
                  <DatePicker
                    id="txtDataFinal"
                    onInputClick={() => {
                      let element = document.getElementById('txtDataFinal')
                      if (element) {
                        element.blur();
                      }
                    }}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date: any) => setDataFinal(date)}
                    selected={dataFinal}
                    locale={"pt-BR"}
                    className='teste'
                  />
                </div>
              </div>
            </div>
            <div className="buttonFiltrar" >
              <Button onClick={() => {getPublications()}}>Filtrar</Button>
            </div>
          </CardDatesContainer>
      }
      </>

    )
  }

  return (
    <Container>
      <Content>

      <ContainerHeaderDashboard>
        <ContainerHeaderDashboardCards>
          <BigBox
            title="saldo"
            color="#08324C"
            amount={counters.totalPublicacoes}
            footerlabel="Total de Publicações"
            icon="dolar"
          />

          <BigBox
            title="entradas"
            color="#075600"
            amount={counters.totalPublicacoesLidas}
            footerlabel="Lidas"
            icon="arrowUp"
          />

          <BigBox
            title="saídas"
            color="#E44C4E"
            amount={counters.totalPublicacoesNaoLidas}
            footerlabel="Não Lidas"
            icon="arrowDown"
          />
        </ContainerHeaderDashboardCards>

        {
          CardDatesComponent()
        }
        {/* {filterDate ? (
          CardDatesComponent()
          ) : (
          <ContainerHeaderDashboardButtons>

              <Button type="submit">Buscar Atualização</Button>
              <Button type="submit" onClick={openModal}><img src={filterImg} style={{width: 15}} />Filtrar</Button>
          </ContainerHeaderDashboardButtons>
          )} */}

      </ContainerHeaderDashboard>

      <ContentPublications>


      <ContentPublicationsCards>

        {
          Loading ?
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginTop: 40, marginBottom: 20, width: '100%', gap: 12}}>
            <div style={{color: 'black'}}>Carregando Dados</div><ClipLoader color="#08324C" size={20}/>
          </div>
          :
          user && publications.length > 0 ?
          <>
          {
            publications.map((publication, index) => (
              <MessageBox
                key={index}
                data={publication}
                onClickAllPublication={(data: any) => {
                  history.push({
                    pathname: `/${user.path}/publications/todos`,
                    state: { publication: data, typeInicial: "todos" }
                  })
                }}
                onClickRead={(data: any) => {
                  history.push({
                    pathname: `/${user.path}/publications/lidas`,
                    state: { publication: data, typeInicial: "lidas" }
                  })
                }}
                onClickNotRead={(data: any) => {
                  history.push({
                    pathname: `/${user.path}/publications/naoLidas`,
                    state: { publication: data, typeInicial: "naoLidas" }
                  })
                }}
              />
            ))
          }
          </>
          :
          <>
            <div style={{height: 200, color: 'black', marginTop: 100, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', textAlign: 'center', gap: 12}}><PublicationIcon/> <span style={{fontWeight: 'bold'}}>Sem publicações para exibir</span></div>
          </>
        }

        </ContentPublicationsCards>
      </ContentPublications>
      </Content>

      <ModalFilter
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </Container>
  );
};

export default Dashboard;
