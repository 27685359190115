import styled from 'styled-components';

export const Container = styled.div`
    grid-area: MH;

    background-color: #FFF;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 2rem;
    filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.25));
`;

export const HeaderProfile = styled.div`

    @media(max-width: 600px){
      display: none;
    }
`;

export const Profile = styled.div`
    color: #00204B;
    flex-direction: row;
    display: flex;
    align-items: center;
`;

export const Welcome = styled.h4`

    @media(max-width: 600px){
      font-size: 12px;
    }
`;

export const UserName = styled.h4`

    @media(max-width: 600px){
      font-size: 12px;
    }
`;

export const Avatar = styled.img`
    height: 58px;
    width: 58px;
    border-radius: 58px;

    @media(max-width: 600px){
      height: 48px;
      width: 48px;
      border-radius: 48px;
    }
`;

export const Logo = styled.img`
    height: 48px;
    width: 48px;
    margin-right: 1rem;

    @media(max-width: 600px){
      margin-left: 2.5rem;
      height: 38px;
      width: 38px;
      margin-right: 0rem;
    }
`;


