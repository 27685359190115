import React, { useEffect, useState } from 'react';
import tribunalImg from '../../assets/tribunal.svg';
import marteloImg from '../../assets/martelo.svg';
import listImg from '../../assets/list.svg';
import shareImg from '../../assets/share.svg';
import clockImg from '../../assets/clock.svg';
import closeImg from '../../assets/close.svg';
import check from '../../assets/verifica.png';
import printer from '../../assets/printer.svg';
import calendarImg from '../../assets/calendar.svg';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import api from '../../services/api';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker'
import formatDate from '../../utils/formatDate';
import "react-datepicker/dist/react-datepicker.css";
import { Router, useLocation } from 'react-router-dom';

import { saveAs } from "file-saver";
import {
  AlignmentType,
  Document,
  HeadingLevel,
  Packer,
  Paragraph,
  TabStopPosition,
  TabStopType,
  TextRun
} from "docx";

import {
  Container, Content, ContainerRow, ContentPublication, Footer,
  ContentPublications,
   CardDatesContainer, ContentPublicationsCards,
    ContainerHeaderDashboard, ContainerHeaderDashboardCards,
     ContentTitlePublications, ContainerLerTudo}  from './styles';

import { useAuth } from '../../contexts/auth';


interface IPublicationDetail {
  id: number;
  codigo_escritorio: number;
  nome_escritorio: string;
  oab_estado: string;
  oab_numero: string;
  ano_publicacao: number;
  cidade_publicacao: string;
  cod_integracao: number;
  cod_publicacao: number;
  cod_vinculo: number;
  data_cadastro: string;
  data_divulgacao: string;
  data_publicacao: string;
  descricao_diario: string;
  descricao_uf: string;
  despacho_publicacao: string;
  nome_diario: string;
  edicao_diario: number;
  nome_vinculo: string;
  numero_processo: string;
  orgao_descricao: string;
  pagina_final: number;
  pagina_inicial: number;
  processo_publicacao: string;
  publicacao_corrigida: number;
  publicacao_exportada: number;
  vara_descricao: string;
}
interface LocationState {
  publication: IPublicationDetail
}


const List:React.FC<IPublicationDetail> = () => {

  const location = useLocation<LocationState>();
  const { publication } = location.state;

  const lerPublicacao = async () => {
    await api.post('/publicacao/ler', {publicacaoList: [publication.id]})
      .then(res => {
        console.log(res.data)
      })
      .catch(res =>
          toast.error('Não foi possível ler as publicações',
          {position: 'top-right'})
      )
  }

  const generate = (publicacaoList: any[], date: string) => {
    const doc = new Document({
      title: "Publicações",
      styles: {
        paragraphStyles: [
            {
                id: "Heading2",
                name: "Heading 2",
                basedOn: "Normal",
                next: "Normal",
                quickFormat: true,
                run: {
                    size: 24,
                },
                paragraph: {
                    alignment: AlignmentType.JUSTIFIED,
                },

            },
        ],
    },
      sections: [
        {
          properties: {},
          children: loadDocument(publicacaoList)
        }
      ]
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `Publicações ${date}.docx`);
      console.log("Document created successfully");
    });
  }

  const loadDocument = (publicacao: any[]) => {
    let document = []
    const lines = user?.rodape ? user?.rodape : ''
    const textRuns = lines.split("\n").map(line=>new TextRun({
      break:1,
      text:line,
    }))

    for (let i=0; i<publicacao.length; i++) {
      document.push(
        new Paragraph({
          children: [
            new TextRun({
              text: "Processo: ",
              bold: true
            }),
            new TextRun({
              text: publicacao[i].numero_processo,
            }),
            new TextRun({
              text: "       ",
            }),
            new TextRun({
              text: "Cliente: ",
              bold: true
            }),
            new TextRun({
              text: publicacao[i].nome_escritorio
            }),
          ]
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: "Variação encontrada: ",
              bold: true
            }),
            new TextRun({
              text: publicacao[i].nome_vinculo,
            }),
          ]
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: "Data Disponibilização: ",
              bold: true
            }),
            new TextRun({
              text: formatDate(publicacao[i].data_divulgacao)
            }),
            new TextRun({
              text: "       ",
            }),
            new TextRun({
              text: "Data Publicação: ",
              bold: true
            }),
            new TextRun({
              text: formatDate(publicacao[i].data_publicacao)
            }),
          ]
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: "Diário: ",
              bold: true
            }),
            new TextRun({
              text: publicacao[i].nome_diario
            }),
          ]
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: "Tribubal: ",
              bold: true
            }),
            new TextRun({
              text: publicacao[i].orgao_descricao
            }),
            new TextRun({
              text: "       ",
            }),
            new TextRun({
              text: "UF: ",
              bold: true
            }),
            new TextRun({
              text: publicacao[i].descricao_uf
            }),
          ]
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: "Orgão Secretaria: ",
              bold: true
            }),
            new TextRun({
              text: publicacao[i].vara_descricao
            }),
          ]
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: " ",
            }),
          ]
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: publicacao[i].processo_publicacao
            })
          ],
          heading: HeadingLevel.HEADING_2,

        }),
        new Paragraph({
          children: [
            new TextRun({
              text: "_________________________________________________________________________",
            }),
          ]
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: "",
            }),
          ]
        }),
        new Paragraph({
          children: textRuns,
          heading: HeadingLevel.HEADING_2,
          alignment: AlignmentType.LEFT,

        }),
        new Paragraph({
          pageBreakBefore: publicacao.length > 1 && user?.breakline
        }),
      )
    }

    return document
  }


  useEffect(() => {
    var el = document.getElementById("container");
    if (el) {
      el.scrollTo(0, 0);
    }
    lerPublicacao()
  }, [])

  const apagarPublicacao = async (id: number) => {
    await api.post('/publicacao/apagar', {publicacaoList: [id]})
      .then((res: any) => {
        window.history.back()
      })
  }

  const restaurarPublicacao = async (id: number) => {
    await api.post('/publicacao/restaurar', {publicacao_id: id})
      .then((res: any) => {
        console.log("res", res)
        document.location.reload()
      })
  }

  const {user} = useAuth()
  const [ShowModal, setShowModal] = useState(false);
  const [dataInicial, setDataInicial] = useState(new Date());

  function normalizeCalendar(text: string) {
    let str = text;
    str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    return str
  }
  function hideKeyboard() {
    //this set timeout needed for case when hideKeyborad
    //is called inside of 'onfocus' event handler
    setTimeout(function() {

      //creating temp field
      var field = document.createElement('input');
      field.setAttribute('type', 'text');
      //hiding temp field from peoples eyes
      //-webkit-user-modify is nessesary for Android 4.x
      field.setAttribute('style', 'position:absolute; top: 0px; opacity: 0; -webkit-user-modify: read-write-plaintext-only; left:0px;');
      document.body.appendChild(field);

      //adding onfocus event handler for out temp field
      field.onfocus = function(){
        //this timeout of 200ms is nessasary for Android 2.3.x
        setTimeout(function() {

          field.setAttribute('style', 'display:none;');
          setTimeout(function() {
            document.body.removeChild(field);
            document.body.focus();
          }, 14);

        }, 200);
      };
      //focusing it
      field.focus();

    }, 50);
  }


  return (
    <Container>
      <Content  id="container">
        <div>
          <h4>Processo: <label>{publication.numero_processo}</label></h4>
        </div>
        <div>
          <h4>Cliente: <label>{publication.nome_escritorio}</label></h4>
        </div>

        <div>
          <h4>Variação encontrada: <label>{publication.nome_vinculo}</label></h4>
        </div>

        <div>
          <h4>Data Disponibilização: <label>{formatDate(publication.data_divulgacao)}</label></h4>
          <h4>Data Publicação: <label>{formatDate(publication.data_publicacao)}</label></h4>
        </div>

        <ContainerRow>
          <img src={tribunalImg} alt="Tribunal" /><h4>Diário: <label>{publication.nome_diario}</label></h4>
        </ContainerRow>
        <ContainerRow>
          <img src={tribunalImg} alt="Tribunal" /><h4>Tribunal: <label>{publication.orgao_descricao}</label></h4>
          <h4>UF: <label>{publication.descricao_uf}</label></h4>
        </ContainerRow>
        <ContainerRow>
          <img src={marteloImg} alt="Martelo" /><h4>Orgão Secretaria: <label>{publication.vara_descricao}</label></h4>
        </ContainerRow>

      <ContentPublication>
        <img src={listImg} alt="Contract" />
        <label>{publication.processo_publicacao}</label>
      </ContentPublication>

      <div style={{borderBottom: '1px solid #00204B20', marginTop: 40}}></div>
      <Footer>
        <div
          onClick={async () => {
            window.open(`/share/${publication.id}`, '_blank')
          }}
          style={{cursor: "pointer", height: 50, width: 120, justifyContent: 'center'}}>
          <img src={shareImg} />
          <label style={{cursor: 'pointer'}}>Compartilhar</label>
        </div>

        <div
          onClick={() => {
            setShowModal(true)
          }}
          style={{cursor: "pointer", height: 50, width: 120, justifyContent: 'center'}}>
          <img src={calendarImg} />
          <label style={{cursor: 'pointer'}}>Agendar</label>
        </div>

        <div
          onClick={() => { generate([publication], formatDate(publication.data_publicacao))}}
          style={{cursor: "pointer", height: 50, width: 120, justifyContent: 'center'}}
        >
          <img src={printer} />
          <label style={{cursor: 'pointer'}}>Imprimir</label>
        </div>


        <div
          onClick={() => { apagarPublicacao(publication.id)}}
          style={{cursor: "pointer", height: 50, width: 120, justifyContent: 'center'}}
        >
          <img src={closeImg} />
          <label style={{cursor: 'pointer'}}>Apagar</label>
        </div>
      </Footer>
      </Content>
      {
        ShowModal &&
        <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', position: 'absolute', top: 0, left:0, right: 0, bottom: 0, backgroundColor: '#00000020', zIndex: 999, color: 'black'}}>
          <div style={{height: 250, width: 550, backgroundColor: 'white', marginTop: 200, borderRadius: 20, padding: 20, marginLeft: 8, marginRight: 8}}>
            <h2 style={{textAlign: 'center', marginTop: 20}}>Qual data deseja agendar</h2>
              <CardDatesContainer>
                <div className="cardcontent" style={{border: '1px solid black', borderRadius: 8, padding: 8}}>
                  <img src={calendarImg} alt="Calendar"  style={{marginRight: 8}}/>
                  <DatePicker id="txtAgendaDate"
                    onInputClick={() => {
                      let element = document.getElementById('txtAgendaDate')
                      if (element) {
                        element.blur();
                      }
                    }} showTimeSelect dateFormat="dd/MM/yyyy HH:mm" onChange={(date: any) => setDataInicial(date)} selected={dataInicial} locale={"pt-BR"} />
                </div>
              </CardDatesContainer>

              <div onClick={hideKeyboard} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginBottom: 20, width: '100%', gap: 12}}>
                <AddToCalendarButton
                  name={`Publicacao: ${publication.numero_processo}`}
                  description={normalizeCalendar(publication.processo_publicacao)}
                  options={['Apple', 'Google', 'MicrosoftTeams', 'Outlook.com', 'iCal', 'Microsoft365']}
                  location={`https://www.informadores.com.br/share/${publication.id}`}
                  startDate={dataInicial ? dataInicial.toISOString().split('T')[0] : ''}
                  endDate={dataInicial ? dataInicial.toISOString().split('T')[0] : ''}
                  startTime={dataInicial ? dataInicial.toISOString().split('T')[1].split('.')[0] : ''}
                  endTime={dataInicial ? dataInicial.toISOString().split('T')[1].split('.')[0] : ''}
                  timeZone="America/Sao_Paulo"
                />
                {/* <button style={{padding: '10px 20px 10px 20px', backgroundColor: "#00204B", color: '#fff', fontWeight: 'bold', borderRadius: 20, height: 40, width: 100 }}
                  onClick={() => createICSFile({data: data})}
                >
                  Agendar
                </button> */}
                <button style={{padding: '10px 20px 10px 20px', backgroundColor: "#fff", color: '#00204B', fontWeight: 'bold', borderRadius: 20, height: 40, width: 100, border: '1px solid #00204B' }}
                  onClick={() => {setShowModal(false)}}
                >Cancelar</button>
              </div>
          </div>
        </div>
      }
    </Container>
  );
};

export default List;
