/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled from 'styled-components';

import signInBackgroundImg from '../../assets/background_signin.png';

export const Container = styled.div`
  height: 100vh;

  display: flex;
  flex: 1;
  align-items: stretch;

  background-color: #fff;
`;

export const Background = styled.div`
  flex: 1;
  background: url(${signInBackgroundImg}) no-repeat center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 14rem 6rem;

  filter: brightness(90%);

  @media(max-width: 770px){
      display: none;
    }

  h1 {
    color: #fff;
  }

  h3 {
    color: #fff;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;
  width: 100%;
  max-width: 700px;
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  max-width: 50%;

  > h2 {
    color: #00204B;
    margin-left: 1.5rem;
  }

  > img {
    width: 88px;
    height: 88px;
  }

  @media(max-width: 600px){
    > img {
      width: 58px;
      height: 58px;
    }

    > h2 {
      color: #00204B;
      margin-left: 1rem;
      font-size: 1rem;
    }
  }
`;

export const Form = styled.form`
  padding: 30px;
  width: 80%;
  display: flex;
  flex-direction: column;

  @media(max-width: 600px){
    width: 100%;
  }
`;

export const LinkRegister = styled.p`
  font-size: 1rem;
  color: #08324C;
  text-align: center;
  margin-top: 30px;
  text-decoration: none;
`;
