import React, {useState} from 'react';

import {
    MdClose,
    MdMenu,
} from 'react-icons/md';

import { useAuth } from '../../contexts/auth';

import {
    Container,
    Header,
    MenuContainer,
    MenuItemLink,
    ToggleMenu,
}  from './styles';

import { ReactComponent as ListImg } from '../../assets/list-white.svg';
import { ReactComponent as ExitImg } from '../../assets/exit.svg';
import calendarImg from '../../assets/calendar-white.svg';
import checklistImg from '../../assets/checklist-white.svg';
import commentImg from '../../assets/comment.svg';
import personImg from '../../assets/person-white.png';

const Aside: React.FC = () => {
    const { signOut, user } = useAuth();

    const [toggleMenuIsOpened, setToggleMenuIsOpened ] = useState(false);

    const handleToggleMenu = () => {
        setToggleMenuIsOpened(!toggleMenuIsOpened);
    }

    return (
        <>
        {
        user &&
        <Container menuIsOpen={toggleMenuIsOpened}>
            <Header>
                <ToggleMenu onClick={handleToggleMenu}>
                { toggleMenuIsOpened ? <MdClose /> : <MdMenu /> }
                </ToggleMenu>
            </Header>

            <MenuContainer>
                <MenuItemLink to={`/${user.path}/`} onClick={handleToggleMenu}>
                  <ListImg style={{marginRight:15}} /> Publicações
                </MenuItemLink>

                <MenuItemLink to={`/${user.path}/publicationsDeleted/`} onClick={handleToggleMenu}>
                  <ListImg style={{marginRight:15}} /> Publicações Apagadas
                </MenuItemLink>

                {/* <MenuItemLink to="/">
                <img src={calendarImg} style={{marginRight:15}} />
                    Agenda
                </MenuItemLink>

                <MenuItemLink to="/">
                <img src={checklistImg} style={{marginRight:15}} />
                    Checklist
                </MenuItemLink> */}

                <MenuItemLink to={`/${user.path}/contato`} onClick={handleToggleMenu}>
                <img src={personImg} style={{marginRight:15}} />
                    Contato
                </MenuItemLink>

                <MenuItemLink to="/" onClick={signOut}>
                  <ExitImg style={{marginRight:15}} />
                  Sair
                </MenuItemLink>
            </MenuContainer>
        </Container>
        }
        </>

    );
}

export default Aside;
