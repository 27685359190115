import styled, {keyframes} from 'styled-components';

import { Link } from 'react-router-dom';

const animate = keyframes`
    0% {
        transform: translateX(-100px);
        opacity: 0;
    }
    50%{
        opacity: .3;
    }
    100%{
        transform: translateX(0px);
        opacity: 1;
    }
`;

export const Container = styled.div`
    width: 100%;
    // box-shadow: 0px 2px 5px gray;
    border: 1px solid #00204B50;
    background-color: #fff;
    color: ${props => props.theme.colors.white};

    border-radius: 10px;

    margin: 10px 0;
    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    animation: ${animate} .5s;

    > header {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    > header label {
        font-size: 18px;
        margin-left: 7px;
        font-weight: bold;
        color: #00204B;
    }

    > footer {
      margin-top: 1.5rem;
      flex-direction: row;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    > footer div {
      display: flex;
      align-items: center;
    }

    > footer label {
        font-size: 1rem;
        margin-left: 7px;
        font-weight: bold;
        color: #00204B;
    }

    @media(max-width: 770px){
        width: 100%;

        img {
                height: 20px;
                width: 20px;
        }

        > header label {
            font-size: 14px;
        }

        > header p, > footer span, > footer label {
            font-size: 0.7rem;
            margin-left: 2px;
        }

        > footer img {
          height: 20px;
                width: 20px;
        }
    }

    @media(max-width: 420px){
        width: 100%;
        height: auto;

        > header p {
            margin-bottom: 15px;
        }
    }
`;
