import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Share from '../pages/share';

const ShareRoutes: React.FC = () => (
    <Switch>
        <Route path="/share/:publicacao_id" component={Share} />
    </Switch>
);

export default ShareRoutes;
