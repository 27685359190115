import styled, {keyframes} from 'styled-components';

import { Link } from 'react-router-dom';

const animate = keyframes`
    0% {
        transform: translateX(-100px);
        opacity: 0;
    }
    50%{
        opacity: .3;
    }
    100%{
        transform: translateX(0px);
        opacity: 1;
    }
`;

export const Container = styled.div<{color: string}>`
    width: 100%;
    // box-shadow: 0px 2px 5px gray;
    border: 1px solid #00204B50;

    background-color: #fff;
    border-left: 12px solid ${p => (p.color ? p.color : "#AF0217")};
    text-decoration: none;

    border-radius: 20px;

    margin: 10px 0;
    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-decoration: none !important;

    animation: ${animate} .5s;
    color: #00204B;



    > footer {
      margin-top: 1.5rem;
      display: inline-flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: row;
      flex-wrap: wrap;
    }

    > footer div {
      display: flex;
      align-items: center;

    }

    > footer label {
        font-size: 16px;
        margin-left: 7px;
        font-weight: bold;
        color: #00204B;
    }

    @media(max-width: 770px){
        width: 100%;

        > header h1 {
            font-size: 24px;

            img {
                height: 20px;
                width: 20px;
            }
        }

        > header p, > footer span {
            font-size: 14px;
        }

        > footer label {
          font-size: 12px;
          margin-left: 7px;
          font-weight: bold;
          color: #00204B;
        }

        > footer img {
          width: 20px;
          height: 20px;
        }
    }

    @media(max-width: 420px){
        width: 100%;
        height: auto;

        > header p {
            margin-bottom: 15px;
        }
    }
`;

export const ContainerContent = styled(Link)`
  a:link, a:visited {
    flex-direction: row;
    display: flex;
    padding-bottom: 1rem;
    align-items: center;
    text-decoration: none !important;
  }
  div {
    flex-direction: row;
    display: flex;
    padding-bottom: 1rem;
    align-items: center;
    text-decoration: none !important;
  }

  div > label {
    margin-left: 2px;
    text-decoration: none !important;
  }

  .clampLabel {
    /* word-break: break-word; */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    height: 100% !important;
    line-height: 30px;
    -webkit-box-orient: vertical;
    text-decoration: none !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    /* -webkit-line-clamp: 3; */
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
  }

  .wrap {
    display: flex;
    flex-wrap: wrap;
  }

  label {
      font-size: 14px;
      text-align: justify;
      color: #00204B;
      margin-right: 0.5rem;
      text-decoration: none !important;
  }

  @media(max-width: 770px){
    width: 100%;

    > header h1 {
        font-size: 24px;

        img {
            height: 20px;
            width: 20px;
        }
    }

    > header p, > footer span {
        font-size: 14px;
    }

    > footer label {
      font-size: 12px;
      margin-left: 7px;
      font-weight: bold;
      color: #00204B;
    }

    > footer img {
      width: 20px;
      height: 20px;
    }
}

@media(max-width: 420px){
    width: 100%;
    height: auto;

    > header p {
        margin-bottom: 15px;
    }
}

`;

export const CardDatesContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
  color: #00204B;

  .dateContentLabel {
    display: flex;
    flex-direction: row;
    width: 20%;
    float: left;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  .dateContent {
    display: flex;
    flex-direction: row;
    width: 40%;
    float: left;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  .buttonFiltrar {
    display: flex;
    flex-direction: row;
    width: 180px;
    margin-right: 16px;
    float: left;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  .cardcontent {
    flex-direction: row;
    display: inline-flex;
    align-items: center;
    margin-left: 8px;
  }

  label {
    font-weight: bold;
    margin-top: 8px;
  }

  p {
    font-weight: bold;
    margin-left: 10px;
  }

  img {
    width: 24px;
    height: 24px;
  }

  .react-date-picker__wrapper {
    border-radius: 8px !important;
    padding: 8px;
  }
  .react-datepicker-popper {
    z-index: 999999 !important;
  }

  @media(max-width: 1100px){
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;

    .cardcontent {
      margin-top: 5px;
    }

    .dateContentLabel {
      width: 100% !important;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
    }

    .dateContent {
      display: flex;
      flex-direction: row;
      width: 100%;
      float: left;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
    }
  }
`;
