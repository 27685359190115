import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Layout from '../components/Layout';
import Dashboard from '../pages/Dashboard';
import Publications from '../pages/Publications';
import PublicationsDeleted from '../pages/PublicationsDeleted';
import Contato from '../pages/Contato';
import List from '../pages/List';

const AppRoutes: React.FC = () => (
    <Layout>
        <Switch>
            <Route path="/:path/" exact component={Dashboard} />
            <Route path="/:path/publications/:type" exact component={Publications} />
            <Route path="/:path/publicationsDeleted" exact component={PublicationsDeleted} />
            <Route path="/:path/list" exact component={List} />
            <Route path="/:path/contato" exact component={Contato} />
            {/* <Route path="/" exact component={Dashboard} />
            <Route path="/publications" exact component={Publications} />
            <Route path="/list" exact component={List} />
            <Route path="/contato" exact component={Contato} /> */}
        </Switch>
    </Layout>
);

export default AppRoutes;
