import styled from 'styled-components';

export const Container = styled.div`
    grid-area: CT;
    color: #FFF;
    background-color: #FFF;

    padding: 25px;

    /* height: calc(100vh - 70px); */
    overflow-y: scroll;

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: ${props => props.theme.colors.secondary};
        border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
        background-color: #F8f8f8;
    }
`;
