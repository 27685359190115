import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { useAuth } from '../contexts/auth';
import App from './app.routes';
import Auth from './auth.routes';
import ShareRoutes from './share.routes';

const Routes: React.FC = () => {
    const { user } = useAuth();
    const path = window.location.href.split("/")

    return (
        <BrowserRouter>
            { path[3] === 'share' ? <ShareRoutes /> : user ? <App/>  : <Auth /> }
        </BrowserRouter>
    );
}

export default Routes;
