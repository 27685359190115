import React from 'react';

import BigBox from '../../components/WalletBox';

import {Container,
  Content, ContentPublications,
   CardDatesContainer, ContentPublicationsCards,
    ContainerHeaderDashboard, ContainerHeaderDashboardCards,
     ContentTitlePublications, ContainerLerTudo}  from './styles';
import Button from '../../components/Button';

import { ReactComponent as PublicationIcon } from '../../assets/publication.svg';
import calendarImg from '../../assets/calendar.svg';
import listImg from '../../assets/list.svg';
import { useState } from 'react';
import { useEffect } from 'react';
import PublicationBox from '../../components/PublicationBox';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { IPublication } from '../../models/Publication';
import ClipLoader from "react-spinners/ClipLoader";
import { useAuth } from '../../contexts/auth';
import formatDate from '../../utils/formatDate';

import { saveAs } from "file-saver";
import {
  AlignmentType,
  Document,
  HeadingLevel,
  Packer,
  PageBreak,
  PageBreakBefore,
  Paragraph,
  TabStopPosition,
  TabStopType,
  TextRun
} from "docx";
import { Alignment } from 'docx';
import { size } from 'lodash';

interface LocationState {
  publication: IPublication,
  typeInicial? : string
}

interface IPublicationDetail {
  id: number;
  codigo_escritorio: number;
  nome_escritorio: string;
  oab_estado: string;
  oab_numero: string;
  ano_publicacao: number;
  cidade_publicacao: string;
  cod_integracao: number;
  cod_publicacao: number;
  cod_vinculo: number;
  data_cadastro: string;
  nome_diario: string;
  data_divulgacao: string;
  data_publicacao: string;
  descricao_diario: string;
  descricao_uf: string;
  despacho_publicacao: string;
  edicao_diario: number;
  nome_vinculo: string;
  numero_processo: string;
  orgao_descricao: string;
  pagina_final: number;
  pagina_inicial: number;
  processo_publicacao: string;
  publicacao_corrigida: number;
  publicacao_exportada: number;
  vara_descricao: string;
}

const Publications: React.FC = () => {
  const [filterDate, setFilterDate] = useState(true);

  let { type } = useParams<any>()

  const location = useLocation<LocationState>();
  const { publication } = location.state;
  const history = useHistory()
  const { user } = useAuth();
  const [TypeFilter, setTypeFilter] = useState(type);

  const [publications, setPublications] = useState<IPublicationDetail[]>([]);
  const [showAllPublicacao, setShowAllPublicacao] = useState<boolean>(false);

  const [Total, setTotal] = useState(0);
  const [Lidas, setLidas] = useState(0);
  const [NaoLidas, setNaoLidas] = useState(0);
  const [loading, setLoading] = useState(true);
  const [Refresh, setRefresh] = useState(0);

  const [ShowModal, setShowModal] = useState(false);

  const [LoadingLeitura, setLoadingLeitura] = useState(false);



  const getPublications = async (type: string) => {
    setLoading(true)
    const dateFormatted = publication.data_publicacao.split('/').reverse().join('-');

    await api.post('/publicacao/getPublicacaoEscritorio', { data_publicacao: dateFormatted, type: type })
    .then(res => {
      setPublications(res.data);
    })
    .catch(res =>
        toast.error('Não foi possível listar as publicações',
        {position: 'top-right'})
    )
    setLoading(false)
  }

  const getPublicationsTotal = async () => {

    const dateFormatted = publication.data_publicacao.split('/').reverse().join('-');

    await api.post('/acesso/dash', {dataInicial: dateFormatted, dataFinal: dateFormatted, all: false})
    .then(res => {
      setTotal(parseInt(res.data.counters.totalPublicacoes));
      setLidas(parseInt(res.data.counters.totalPublicacoesLidas));
      setNaoLidas(parseInt(res.data.counters.totalPublicacoesNaoLidas));
    })
    .catch(res =>
        toast.error('Não foi possível listar as publicações',
        {position: 'top-right'})
    )
  }

  const loadData = async (filter: string) => {
    setLoading(true)
    if (filter) {
      getPublications(filter);
    }
    else {
      getPublications(TypeFilter ? TypeFilter : "todos");
    }
    getPublicationsTotal()
  }

  const loadDocument = (publicacao: any[]) => {
    let document = []
    const lines = user?.rodape ? user?.rodape : ''
    const textRuns = lines.split("\n").map(line=>new TextRun({
      break:1,
      text:line,
    }))

    for (let i=0; i<publicacao.length; i++) {

      document.push(
        new Paragraph({
          children: [
            new TextRun({
              text: "Processo: ",
              bold: true
            }),
            new TextRun({
              text: publicacao[i].numero_processo,
            }),
            new TextRun({
              text: "       ",
            }),
            new TextRun({
              text: "Cliente: ",
              bold: true
            }),
            new TextRun({
              text: publicacao[i].nome_escritorio
            }),
          ]
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: "Variação encontrada: ",
              bold: true
            }),
            new TextRun({
              text: publicacao[i].nome_vinculo,
            }),
          ]
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: "Data Disponibilização: ",
              bold: true
            }),
            new TextRun({
              text: formatDate(publicacao[i].data_divulgacao)
            }),
            new TextRun({
              text: "       ",
            }),
            new TextRun({
              text: "Data Publicação: ",
              bold: true
            }),
            new TextRun({
              text: formatDate(publicacao[i].data_publicacao)
            }),
          ]
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: "Diário: ",
              bold: true
            }),
            new TextRun({
              text: publicacao[i].nome_diario
            }),
          ]
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: "Tribubal: ",
              bold: true
            }),
            new TextRun({
              text: publicacao[i].orgao_descricao
            }),
            new TextRun({
              text: "       ",
            }),
            new TextRun({
              text: "UF: ",
              bold: true
            }),
            new TextRun({
              text: publicacao[i].descricao_uf
            }),
          ]
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: "Orgão Secretaria: ",
              bold: true
            }),
            new TextRun({
              text: publicacao[i].vara_descricao
            }),
          ]
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: " ",
            }),
          ]
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: publicacao[i].processo_publicacao
            })
          ],
          heading: HeadingLevel.HEADING_2,

        }),
        new Paragraph({
          children: [
            new TextRun({
              text: "_________________________________________________________________________",
            }),
          ]
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: "",
            }),
          ]
        }),
        new Paragraph({
          children: textRuns,
          heading: HeadingLevel.HEADING_2,
          alignment: AlignmentType.LEFT,

        }),
        new Paragraph({
          pageBreakBefore: publicacao.length > 1 && user?.breakline
        }),
      )
    }

    return document
  }

  useEffect(() => {
    if (TypeFilter) {
      loadData(TypeFilter)
    }
  }, [publication.data_publicacao]);

  useEffect(() => {
    getPublications(type)
    setRefresh(Refresh+1)
    setTypeFilter(type)
  }, [type])


  const lerTodas = async () => {
    setLoadingLeitura(true)

    let idsPub = []
    for (let i=0; i<publications.length; i++) {
      idsPub.push(publications[i].id)
    }

    await api.post('/publicacao/ler', {publicacaoList: idsPub})
    .then(res => {
      setShowAllPublicacao(true)
      setRefresh(Refresh+1)
      // loadData(TypeFilter)
    })
    .catch(res =>
        toast.error('Não foi possível ler as publicações',
        {position: 'top-right'})
    )
    setShowModal(false)
    setLoadingLeitura(false)

  }

  const generate = (publicacaoList: any[], date: string) => {
    const doc = new Document({
      title: "Publicações",
      styles: {
        paragraphStyles: [
            {
                id: "Heading2",
                name: "Heading 2",
                basedOn: "Normal",
                next: "Normal",
                quickFormat: true,
                run: {
                    size: 24,
                },
                paragraph: {
                    alignment: AlignmentType.JUSTIFIED,
                },

            },
        ],
    },
      sections: [
        {
          properties: {},
          children: loadDocument(publicacaoList)
        }
      ]
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `Publicações ${date}.docx`);
      console.log("Document created successfully");
    });
  }

  return (
    <>
      { user &&
        <Container>
        {
          ShowModal &&
          <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', position: 'absolute', top: 0, left:0, right: 0, bottom: 0, backgroundColor: '#00000020', zIndex: 999, color: 'black'}}>
            <div style={{height: 250, width: 550, backgroundColor: 'white', marginTop: 200, borderRadius: 20, padding: 20, marginLeft: 8, marginRight: 8}}>
              <h2 style={{textAlign: 'center', marginTop: 20}}>Deseja ler todas as publicações<br></br> listadas abaixo?</h2>
                {
                  LoadingLeitura ?
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: 40, marginBottom: 20, width: '100%', gap: 12}}>
                      <div>Lendo todas as publicações selecionadas...</div>
                      <div style={{fontSize: 12}}>Esse processo pode demorar dependendo da quantidade de publicações do dia.</div>
                      <ClipLoader color="#08324C" size={20}/>
                    </div>
                  :
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginTop: 40, marginBottom: 20, width: '100%', gap: 12}}>
                      <button style={{padding: '10px 20px 10px 20px', backgroundColor: "#00204B", color: '#fff', fontWeight: 'bold', borderRadius: 20, height: 40, width: 100 }}
                        onClick={() => lerTodas()}
                      >
                        Sim
                      </button>
                      <button style={{padding: '10px 20px 10px 20px', backgroundColor: "#fff", color: '#00204B', fontWeight: 'bold', borderRadius: 20, height: 40, width: 100, border: '1px solid #00204B' }}
                        onClick={() => {setShowModal(false)}}
                      >Não</button>
                    </div>
                }
            </div>
          </div>
        }

        <Content>

        <ContainerHeaderDashboard>
          <ContainerHeaderDashboardCards>
            <BigBox
              title="saldo"
              color="#08324C"
              amount={Total}
              footerlabel="Total de Publicações"
              icon="dolar"
              onClick={() => {
                history.push({
                  pathname: `/${user.path}/publications/todos`,
                  state: { publication, typeInicial: "todos" }
                })
                getPublications("todos")
                setTypeFilter("todos")
              }}
              style={{ boxShadow: TypeFilter === 'todos' ? "2px 6px 1px #000000AB" : 'none'}}
            />
            <BigBox
              title="entradas"
              color="#075600"
              amount={Lidas}
              footerlabel="Lidas"
              icon="arrowUp"
              onClick={() => {
                history.push({
                  pathname: `/${user.path}/publications/lidas`,
                  state: { publication, typeInicial: "" }
                })
                getPublications("lidas")
                setTypeFilter("lidas")
              }}
              style={{ boxShadow: TypeFilter === 'lidas' ? "2px 6px 1px #000000AB" : 'none'}}
            />
            <BigBox
              title="saídas"
              color="#E44C4E"
              amount={NaoLidas}
              footerlabel="Não Lidas"
              icon="arrowDown"
              onClick={() => {
                history.push({
                  pathname: `/${user.path}/publications/naoLidas`,
                  state: { publication, typeInicial: "naoLidas" }
                })
                getPublications("naoLidas")
                setTypeFilter("naoLidas")
              }}
              style={{ boxShadow: TypeFilter === 'naoLidas' ? "2px 6px 1px #000000AB" : 'none'}}
            />
          </ContainerHeaderDashboardCards>
        </ContainerHeaderDashboard>

        <ContentPublications>
        <ContainerLerTudo>
          <Button style={{width: 150}}
            onClick={() => {
              setShowModal(true)
            }}
          >
            Ler todas as Publicações
          </Button>
          <Button style={{width: 150}}
            onClick={() => {
              // toast.error('Erro ao gerar Documento',
              // {position: 'top-right'})

              generate(publications, publication.dia)
            }}
          >
            Imprimir Todos
          </Button>
        </ContainerLerTudo>

        <ContentTitlePublications>
          <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
            <img src={listImg} alt="Contract" />
            <h3>{publication.dia}</h3>
          </div>
        </ContentTitlePublications>

        <ContentPublicationsCards>
          {
            Refresh && loading ?
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginTop: 40, marginBottom: 20, width: '100%', gap: 12}}>
                <div style={{color: 'black'}}>Carregando Dados</div><ClipLoader color="#08324C" size={20}/>
              </div>
            :
            Refresh && publications.length > 0 ?
                publications.map(publication => (
                  <PublicationBox key={publication.id} data={publication} lerTodas={showAllPublicacao} onPrint={(publicacaoList, date) => generate(publicacaoList, date)} deletedIcon={true}/>
                ))
              :
            <div style={{height: 200, color: 'black', marginTop: 100, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', textAlign: 'center', gap: 12}}><PublicationIcon/> <span style={{fontWeight: 'bold'}}>Sem publicações para exibir</span></div>
            }
        </ContentPublicationsCards>
        </ContentPublications>
        </Content>
      </Container>
    }
    </>

  );
};

export default Publications;
