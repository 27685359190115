import styled, { css, keyframes } from 'styled-components';

import { Link } from 'react-router-dom';
interface IContainerProps {
    menuIsOpen: boolean;
}
interface IThemeToggleFooterProps {
    menuIsOpen: boolean;
}

const animate = keyframes`
    0% {
        transform: translateX(-70%);
    }
    100%{
        transform: translateX(0px);
    }
`;



export const Container = styled.div<IContainerProps>`
    grid-area: AS;

    background-color: #00204B;
    padding-left: 20px;

    position: relative;

    @media(max-width: 600px){

        animation: ${props => props.menuIsOpen ? animate : null} .5s;

        background-color: ${props => props.menuIsOpen  ? '#00204B' : 'transparent'};
        padding-left: 20px;
        position: fixed;
        z-index: 2;

        width: 70%;

        height: ${props => props.menuIsOpen ? '100vh' : '70px'};
        overflow: hidden;

        ${props => !props.menuIsOpen && css`
            border: none;
        `};
    }
`;

export const Header = styled.header`
    height: 70px;
    display: flex;
    align-items: center;

`;

export const LogImg = styled.img`
    height: 40px;
    width: 40px;

    @media(max-width: 600px){
        display: none;
    }
`;

export const Title = styled.h3`
    color: #000;
    margin-left: 10px;

    @media(max-width: 600px){
        display: none;
    }
`;


export const MenuContainer = styled.nav`
    display: flex;
    flex-direction: column;

    @media(min-width: 1100px){
      margin-top: -15%;
    }
`;

export const MenuItemLink = styled(Link)`
    color: #fff;
    text-decoration: none;

    margin: 14px 0;
    display: flex;
    align-items: center;

    transition: opacity .3s;

    font-weight: bold;

    &:hover {
        opacity: .7;
    }

    > svg {
        font-size: 14px;
        margin-right: 5px;
    }
`;

export const MenuItemButton = styled.button`
    font-size: 16px;
    color: #fff;

    border: none;
    background: none;

    margin: 7px 0;
    display: flex;
    align-items: center;

    transition: opacity .3s;

    &:hover {
        opacity: .7;
    }

    > svg {
        font-size: 18px;
        margin-right: 5px;
    }
`;

export const ToggleMenu = styled.button`
    width: 40px;
    height: 40px;

    border-radius: 5px;
    font-size: 22px;

    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.primary};

    transition: opacity .3s;

    &:hover{
        opacity: 0.7;
    }

    display: none;

    @media(max-width: 600px){
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const ThemeToggleFooter = styled.footer<IThemeToggleFooterProps>`
    display: none;
    position: absolute;
    bottom: 30px;

    @media(max-width: 470px){
        display: ${props => props.menuIsOpen ? 'flex' : 'none'};
    }

`;
