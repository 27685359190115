import React, { useEffect, useState } from 'react';
import { Container, Content, ContainerRow, ContentPublication, Footer } from './styles';

import tribunalImg from '../../assets/tribunal.svg';
import marteloImg from '../../assets/martelo.svg';
import listImg from '../../assets/list.svg';
import shareImg from '../../assets/share.svg';
import clockImg from '../../assets/clock.svg';
import closeImg from '../../assets/close.svg';
import api from '../../services/api';
import { toast } from 'react-toastify';

import formatDate from '../../utils/formatDate';
import { useLocation, useParams } from 'react-router-dom';


interface IPublicationDetail {
  id: number;
  codigo_escritorio: number;
  nome_escritorio: string;
  oab_estado: string;
  oab_numero: string;
  ano_publicacao: number;
  cidade_publicacao: string;
  cod_integracao: number;
  cod_publicacao: number;
  cod_vinculo: number;
  data_cadastro: string;
  data_divulgacao: string;
  data_publicacao: string;
  descricao_diario: string;
  descricao_uf: string;
  despacho_publicacao: string;
  nome_diario: string;
  edicao_diario: number;
  nome_vinculo: string;
  numero_processo: string;
  orgao_descricao: string;
  pagina_final: number;
  pagina_inicial: number;
  processo_publicacao: string;
  publicacao_corrigida: number;
  publicacao_exportada: number;
  vara_descricao: string;
}
interface LocationState {
  publication: IPublicationDetail
}


const List:React.FC = () => {

  const [publication, setPublication] = useState<IPublicationDetail>();

  let { publicacao_id } = useParams<any>()

  useEffect(() => {
    var el = document.getElementById("container");
    if (el) {
      el.scrollTo(0, 0);
    }

    loadPublicacao()

  }, [publicacao_id])

  const loadPublicacao = async () => {
    let res = await api.post('/share', {publicacao_id})
    if (res && res.data) {
      setPublication(res.data.value[0])
      console.log(res.data.value[0])
    }
  }


  return (
    <Container>
      {
        publication &&
          <>
            <div style={{height: 80, width: '100%', backgroundColor: '#08324C'}}>
            </div>
            <Content  id="container" style={{margin: 20}}>
              <div>
                <h4>Processo: <label>{publication.numero_processo}</label></h4>
              </div>

              <div>
                <h4>Cliente: <label>{publication.nome_escritorio}</label></h4>
              </div>

              <div>
                <h4>Variação encontrada: <label>{publication.nome_vinculo}</label></h4>
              </div>

              <div>
                <h4>Data Disponibilização: <label>{formatDate(publication.data_divulgacao)}</label></h4>
                <h4>Data Publicação: <label>{formatDate(publication.data_publicacao)}</label></h4>
              </div>

              <ContainerRow>
                <img src={tribunalImg} alt="Tribunal" /><h4>Diário: <label>{publication.nome_diario}</label></h4>
              </ContainerRow>
              <ContainerRow>
                <img src={tribunalImg} alt="Tribunal" /><h4>Tribunal: <label>{publication.orgao_descricao}</label></h4>
              </ContainerRow>
              <ContainerRow>
                <h4><img src={tribunalImg} alt="Tribunal" />UF: <label>{publication.descricao_uf}</label></h4>
              </ContainerRow>
              <ContainerRow>
                <img src={marteloImg} alt="Martelo" /><h4>Orgão Secretaria: <label>{publication.vara_descricao}</label></h4>
              </ContainerRow>

              <ContentPublication>
                <img src={listImg} alt="Contract" />
                <label>{publication.processo_publicacao}</label>
              </ContentPublication>
            </Content>
          </>
      }
    </Container>
  );
};

export default List;
