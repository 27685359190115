import React from 'react';

import { Container }  from './styles';

import listImg from '../../assets/list.svg';
import calendarImg from '../../assets/calendar.svg';

import { IPublication } from '../../models/Publication';
interface MessageBoxProps {
    data: any;
    onClickAllPublication?: (date: any) => void
    onClickRead?: (date: any) => void
    onClickNotRead?: (date: any) => void
}



const MessageBox: React.FC<MessageBoxProps> = ({
    data,
    onClickAllPublication,
    onClickRead,
    onClickNotRead
}) => {

  return (
        <Container
          // to={{
          //   pathname: "/publications",
          //   state: { publication: data }
          // }}
        >
        <header>
          <img src={calendarImg} />
          <label>
            {data.dia}
          </label>
        </header>

        <footer>
          <div
            onClick={() => {
              if (onClickAllPublication) {
                onClickAllPublication(data)
              }
            }}
            style={{cursor: 'pointer'}}
          >
            <img src={listImg} />
            <label style={{color: '#00204B', cursor: 'pointer'}}>{data.qtdePublicacao} Publicações</label>
          </div>

          <div
            onClick={() => {
              if (onClickRead) {
                onClickRead(data)
              }
            }}
            style={{cursor: 'pointer'}}
          >
            <img src={listImg} />
            <label style={{color: '#075600', cursor: 'pointer'}}>{data.qtdeLido} Lida(s)</label>
          </div>

          <div
            onClick={() => {
              if (onClickNotRead) {
                onClickNotRead(data)
              }
            }}
            style={{cursor: 'pointer'}}
          >
            <img src={listImg} />
            <label style={{color: '#E44C4E', cursor: 'pointer'}}>{data.qtdeNaoLido} Não Lida(s)</label>
          </div>
        </footer>
      </Container>
  );
}


export default MessageBox;
